import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useLanguageQuery } from 'next-export-i18n';
import { Badge, Box, TableCell, TableRow, Typography } from '@mui/material';

import TransactionService from '@/services/transaction';
import { useTranslation } from '@/hooks/translations';
import { covertUtcToRestaurantDateTime, getCurrencyWithAmount } from '@/common/utility';
import { IPaymentTransaction, ReceiptDataIds } from '@/views/Orders/types';
import { transformOrder } from '@/views/Orders';
import { useRestaurantContext } from '@/contexts/restaurant';

import { PaymentRecordType } from '@/views/Payments';
import { onPushEvent } from '@/services/lib/gtm';
import handleDownloadPdf from '@/common/downloadPdf';
import { Payment } from '@mui/icons-material';
import CustomModal from '../CustomModal';
import { Cell, Section, TableContent } from '../common';
import LoadingContent from '../LoadingContent';
import InvoiceTable from '../InvoiceTable';
import { IPaymentDetailsResponse } from '../InvoiceTable/types';
import StatusChip from '../PaymentLink/StatusChip';
import { parsePaymentDetails } from '../InvoiceTable/transformer';

type Props = {
    restaurantId: string | null;
    open: boolean;
    onClose: () => void;
    currencySymbol: string;
    currencyCode: string;
    showTip: boolean;
    hasVoucher: boolean;
    hasLoyalty: boolean;
    setPrintDoc?: Dispatch<SetStateAction<IPaymentTransaction | undefined>>;
    setPdfDoc?: Dispatch<SetStateAction<IPaymentTransaction | undefined>>;
} & (
    | {
          ids: ReceiptDataIds | null;
          id?: never;
      }
    | { ids?: never; id: string | null }
);

export default function TicketModal({
    id,
    ids,
    restaurantId,
    currencySymbol,
    currencyCode,
    showTip,
    hasVoucher,
    // hasLoyalty,
    setPdfDoc,
    setPrintDoc,
    ...rest
}: Props) {
    const transactionService = TransactionService.getInstance();
    const { t } = useTranslation('common');
    const [data, setData] = useState<IPaymentTransaction | null>(null);
    const [receiptData, setReceiptData] = useState<IPaymentDetailsResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const tableSessionId = ids ? ids.tableSessionId : id;
    const paymentRecordId = ids ? ids.paymentRecordId : undefined;

    const { restaurant } = useRestaurantContext();

    const showGrossTip = restaurant?.posAccess?.pos_vendor_data?.tipsSubmitMode === 'gross';

    const itemLayout = restaurant?.posAccess?.pos_vendor_data.itemLayout;
    const billLayout = restaurant?.posAccess?.pos_vendor_data.billLayout;

    const showForex = restaurant?.config?.enableForexBadge || false;

    const getTableLayout = () => {
        const layout: any = {};
        if (itemLayout) {
            layout.itemLayout = JSON.parse(itemLayout || '[]');
        }
        if (billLayout) {
            layout.billLayout = JSON.parse(billLayout || '[]');
        }

        return layout;
    };

    useEffect(() => {
        if (restaurantId && tableSessionId) {
            setLoading(true);
            transactionService
                .getTransaction(restaurantId, tableSessionId)
                .then((res) => setData(transformOrder(res.data.data, lang, currencyCode, currencySymbol)))
                .catch(() => setError(t('something went wrong')))
                .finally(() => setLoading(false));
            if (paymentRecordId) {
                transactionService
                    .getReceiptData(restaurantId, tableSessionId, paymentRecordId)
                    .then((res) => setReceiptData(res))
                    .catch(() => setError(t('something went wrong')))
                    .finally(() => setLoading(false));
            }
        }
    }, [restaurantId, ids, id]);
    return (
        <CustomModal
            {...rest}
            actionText={setPrintDoc && t('Print')}
            actionMethod={() => {
                if (restaurant && setPrintDoc) {
                    setPrintDoc(data || undefined);
                } else if (data) {
                    handleDownloadPdf(data);
                }
                onPushEvent('user_click_download_receipt');
            }}
            actionText2={(restaurant && setPdfDoc && t('Download')) || ''}
            actionMethod2={() => {
                if (!setPdfDoc) return;
                setPdfDoc(data || undefined);
                onPushEvent('user_click_print_receipt');
            }}
        >
            <LoadingContent loading={loading} error={error}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="h5">{data?.table_data.name}</Typography>
                        <Typography color="#A9A9A9">
                            {t('Ticket  #{{order_id}}, {{time}}, ', {
                                order_id: data?.order_id,
                                time: covertUtcToRestaurantDateTime(data?.created_at, 'HH:mm'),
                            })}
                            {covertUtcToRestaurantDateTime(data?.created_at, 'MM/DD/YYYY')}
                        </Typography>
                        {hasVoucher && (
                            <Typography color="#FF0000">
                                {t('This order is paid by voucher, needs to be closed manually using the POS.')}
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            '& > *': {
                                margin: '0.1rem 0',
                            },
                        }}
                    >
                        <Typography variant="h5">{data?.order?.customerPaid || ''}</Typography>
                    </Box>
                </Box>
                {!!data?.paymentRecord?.length && (
                    <Section sx={{ margin: '0.5rem 0' }}>
                        <TableContent
                            large
                            headCells={
                                <>
                                    <TableCell>{t('Time')}</TableCell>
                                    {showTip && <TableCell>{t('Tips')}</TableCell>}
                                    <TableCell>{t('Method')}</TableCell>
                                    <TableCell>{t('Last 4 digits')}</TableCell>
                                    {data?.country_code === 'sa' && <TableCell>{t('Mada')}</TableCell>}
                                    <TableCell>{t('Status')}</TableCell>
                                    <TableCell>{t('Total')}</TableCell>
                                </>
                            }
                        >
                            {data?.paymentRecord?.map((r, idx) => {
                                if (!(r.status === 'rejected' || r.status === 'refunded' || r.status === 'accepted')) {
                                    return null;
                                }

                                const hasExchangeDetails = r.pg_extra_info?.exchangeDetails;
                                return (
                                    <TableRow key={idx}>
                                        <Cell>{covertUtcToRestaurantDateTime(r.created_at, 'HH:mm')}</Cell>
                                        {showTip && (
                                            <Cell>
                                                {getCurrencyWithAmount(
                                                    Number(r.tip_amount) - (showGrossTip ? 0 : r.tip_commission),
                                                    currencySymbol,
                                                    currencyCode,
                                                )}
                                            </Cell>
                                        )}
                                        <Cell>
                                            {r.gateway_vendor === 'qlub' || r.gateway_vendor === 'loyalty-comoV2'
                                                ? r.type === PaymentRecordType.Voucher
                                                    ? t('Voucher')
                                                    : r.type === PaymentRecordType.Loyalty
                                                    ? t('Loyalty')
                                                    : t('Qlub')
                                                : r.pg_extra_info?.cardBrand}
                                        </Cell>
                                        <Cell>{r.pg_extra_info?.last4}</Cell>
                                        {data?.country_code === 'sa' &&
                                            (r.pg_extra_info?.madaCard === 'mada' ? (
                                                <Cell>{t('yes')}</Cell>
                                            ) : (
                                                <Cell>{t('no')}</Cell>
                                            ))}
                                        <Cell>
                                            <StatusChip status={r.status} />
                                        </Cell>
                                        <Badge
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            badgeContent={
                                                showForex && hasExchangeDetails ? (
                                                    <>
                                                        <Payment
                                                            sx={{ width: '10px', height: '10px', marginRight: '5px' }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                height: '24px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '500',
                                                                fontSize: '8px',
                                                                lineHeight: '140%',
                                                                textAlign: 'center',
                                                                letterSpacing: '0.25px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            {t('Forex')}
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    0
                                                )
                                            }
                                            color="secondary"
                                            showZero={false}
                                        >
                                            <Cell
                                                sx={{
                                                    height: '24px',
                                                }}
                                            >
                                                {getCurrencyWithAmount(r.paid_amount, currencySymbol, currencyCode)}
                                            </Cell>
                                        </Badge>
                                    </TableRow>
                                );
                            })}
                        </TableContent>
                    </Section>
                )}
                <Typography sx={{ marginTop: '1rem' }}>{t('order details')}</Typography>
                <Section sx={{ marginTop: '0.5rem' }}>
                    {restaurant?.config?.receiptBuilderEnabled && receiptData && paymentRecordId ? (
                        <InvoiceTable
                            details={parsePaymentDetails(
                                receiptData,
                                getTableLayout().billLayout?.length > 0 || getTableLayout().itemLayout?.length > 0
                                    ? {
                                          cc: restaurant?.country_code || '',
                                          vendor: restaurant,
                                      }
                                    : undefined,
                            )}
                            lang={lang}
                        />
                    ) : (
                        <TableContent
                            headCells={
                                <>
                                    <TableCell>{t('item')}</TableCell>
                                    <TableCell>{t('QTY')}</TableCell>
                                    <TableCell align="right">{t('Price')}</TableCell>
                                </>
                            }
                        >
                            {data?.order_data?.items?.map((item, i) => (
                                <>
                                    <TableRow key={`${item.title}_${i}`}>
                                        <Cell>{item.title}</Cell>
                                        <Cell>{item.qty}</Cell>
                                        <Cell align="right">{parseFloat(item.unitPrice || '0').toFixed(2)}</Cell>
                                    </TableRow>
                                    {(item.toppings || [])?.map((topping, j) => (
                                        <TableRow key={`${topping.title}_${i}_${j}`}>
                                            <Cell>&nbsp;&nbsp;&nbsp;&nbsp;{topping.title}</Cell>
                                            <Cell>{topping.qty}</Cell>
                                            <Cell align="right">{parseFloat(topping.unitPrice || '0').toFixed(2)}</Cell>
                                        </TableRow>
                                    ))}
                                </>
                            ))}
                        </TableContent>
                    )}
                </Section>
            </LoadingContent>
        </CustomModal>
    );
}
