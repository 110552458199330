import { Button, Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'next-export-i18n';
import { Dispatch, SetStateAction } from 'react';
import { getDateAndTime, getRelativeTime } from '@/services/utils/k_time';
import { getCurrencyWithAmount } from '@/common/utility';
import { OrderPriceRoundingModeEnum } from '@/views/OrdersTableView/types';
import { IQsrOrder, OrderStatusEnum, statusStylesType } from './types';

interface IProps {
    setSelectedRow: Dispatch<SetStateAction<IQsrOrder | null>>;
    currencySymbol: string;
    currencyCode: string;
    priceRoundingMode?: OrderPriceRoundingModeEnum;
}

export const useColumns = ({ setSelectedRow, currencySymbol, currencyCode, priceRoundingMode }: IProps) => {
    const { t } = useTranslation('common');

    const statusStyles: statusStylesType = {
        pending: {
            backgroundColor: '#FF7A00',
            color: '#fff',
        },
        approved: {
            backgroundColor: '#C7FBE5',
            color: '#1C8659',
        },
        declined: {
            backgroundColor: '#FF0000',
            color: '#fff',
        },
        cancelled: {
            backgroundColor: '#FF7A00',
            color: '#fff',
        },
        failed: {
            backgroundColor: '#FF0000',
            color: '#fff',
        },
        syncFailed: {
            backgroundColor: '#FF0000',
            color: '#fff',
        },
    };

    const getOrderStatusText = (status?: OrderStatusEnum) => {
        switch (status) {
            case OrderStatusEnum.Pending:
                return t('Pending');
            case OrderStatusEnum.Approved:
                return t('Approved');
            case OrderStatusEnum.Declined:
                return t('Declined');
            case OrderStatusEnum.Cancelled:
                return t('Cancelled');
            case OrderStatusEnum.Failed:
                return t('Failed');
            case OrderStatusEnum.Active:
                return t('Active');
            case OrderStatusEnum.PickUp:
                return t('Ready for Pick up');
            case OrderStatusEnum.SyncFailed:
                return t('Sync Failed');
            default:
                return status;
        }
    };

    const columns = [
        {
            name: 'ID',
            selector: (row: IQsrOrder) => row.id,
            key: 'id',
            width: '4rem',
        },
        {
            name: 'Ref Id',
            selector: (row: IQsrOrder) => row.refId,
            key: 'refId',
        },
        {
            name: 'Ticket Id',
            selector: (row: IQsrOrder) => row.ticketId,
            key: 'ticketId',
        },
        {
            name: 'Dining Option',
            selector: (row: IQsrOrder) => row.diningOption || '-',
            key: 'diningOption',
        },
        {
            name: 'Location',
            selector: (row: IQsrOrder) => {
                if (row.urlData.tableId.startsWith('-')) {
                    return `Pager: ${row.urlData.name || row.urlData.tableId.substring(1)}`;
                }
                return `Table: ${row.urlData.name || row.urlData.tableId}`;
            },
            center: true,
            key: 'tableId',
        },
        {
            name: 'Total',
            selector: (row: IQsrOrder) =>
                getCurrencyWithAmount(row.total, currencySymbol, currencyCode, true, priceRoundingMode),
            center: true,
            key: 'total',
        },
        {
            name: 'Order Status',
            cell: (row: IQsrOrder) => {
                return (
                    <Chip
                        label={getOrderStatusText(row.orderStatus?.name)}
                        sx={{
                            ...statusStyles[row.orderStatus?.name || 'pending'],
                            borderRadius: '0.2rem',
                            width: '6rem',
                        }}
                    />
                );
            },
            center: true,
            key: 'orderStatus',
        },
        {
            name: 'Details',
            cell: (row: any) => {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSelectedRow(row);
                        }}
                    >
                        {t('Details')}
                    </Button>
                );
            },

            center: true,
            key: 'details',
        },
        {
            name: 'Time',
            cell: (row: IQsrOrder) => (
                <Tooltip title={getDateAndTime(row.paidAt || row.createdAt)}>
                    <span>{getRelativeTime(row.paidAt || row.createdAt)}</span>
                </Tooltip>
            ),
            center: true,
            key: 'date',
            width: '11rem',
        },
        {
            name: 'POS Vendor',
            selector: (row: IQsrOrder) => row.posVendor,
            center: true,
            key: 'posVendor',
        },
    ];

    return columns;
};
