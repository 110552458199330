import { useState, useEffect } from 'react';
import parseJson from 'parse-json';

function useLocalStorage(key: string, initialValue: any) {
    // Check if localStorage is supported in the current browser
    const isLocalStorageSupported = typeof window !== 'undefined' && 'localStorage' in window;

    // Get the initial value from localStorage or use the provided initialValue
    const storedValue = isLocalStorageSupported ? localStorage.getItem(key) : null;
    const initial = storedValue ? JSON.parse(storedValue) : initialValue;

    // Create a state variable to store the current value
    const [value, setValue] = useState(initial);

    // Create a state variable to track whether the component has mounted
    const [hasMounted, setHasMounted] = useState(false);

    // Function to set a new value in localStorage and update the state
    const setStoredValue = (newValue: string) => {
        // Update the state
        setValue(newValue);

        // Store the new value in localStorage if it's supported
        if (isLocalStorageSupported) {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    };

    // Use useEffect to set hasMounted to true when the component mounts
    useEffect(() => {
        setHasMounted(true);
    }, []);

    // Use useEffect to remove the item from localStorage if the value is null
    useEffect(() => {
        if (hasMounted && isLocalStorageSupported && value === null) {
            localStorage.removeItem(key);
        }
    }, [hasMounted, isLocalStorageSupported, key, value]);

    // Use another useEffect to update the local value when localStorage changes
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isLocalStorageSupported) {
            const handleStorageChange = (e: StorageEvent) => {
                if (e.key === key) {
                    if (e.newValue !== null) {
                        setValue(parseJson(e.newValue));
                    } else {
                        // Handle the case where e.newValue is null (e.g., item removed from localStorage)
                        setValue(null);
                    }
                }
            };

            // Listen for changes in localStorage
            window.addEventListener('storage', handleStorageChange);

            return () => {
                // Clean up the event listener
                window.removeEventListener('storage', handleStorageChange);
            };
        }
    }, [isLocalStorageSupported, key]);

    return [value, setStoredValue];
}

export default useLocalStorage;
