import {
    Collapse,
    FormControl,
    FormControlLabel,
    FormLabel,
    Checkbox,
    Autocomplete,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    GlobalStyles,
} from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import { emailRegex } from '@/constants/regexes';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    REPORT_TYPE,
    ATTACHMENT_TYPE,
    ATTACHMENT_DATE_RANGE,
    WEEK_DAYS,
    RestaurantReportPreferences,
    PERIOD,
} from '@/services/utils/schedulerReport_helper';
import { useEffect, useState } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ToggleButtonGroup from '@/components/Dashboard/ToggleButton';
import { Section } from '../../common';

const customTimePickerStyles = (
    <GlobalStyles
        styles={{
            '.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root:after': {
                height: 'auto',
            },
        }}
    />
);
interface ScheduleReportSectionProps {
    showScheduleForm: boolean;
    handleCheckboxChange: (event: any) => void;
}

const initialRestaurantPreferences: RestaurantReportPreferences = {
    reportType: REPORT_TYPE.ORDER_VIEW,
    dateRange: ATTACHMENT_DATE_RANGE.TODAY,
    period: PERIOD.DAILY,
    attachmentType: ATTACHMENT_TYPE.NONE,
    reportTime: {
        hour: new Date().getHours().toString(),
        minute: new Date().getMinutes().toString(),
        day: WEEK_DAYS.SUNDAY,
    },
    cc: [],
    scheduleName: '',
};

const GenerateReportOverScheduled = ({ showScheduleForm, handleCheckboxChange }: ScheduleReportSectionProps) => {
    const { t } = useTranslation('common');
    const { setFieldValue } = useFormikContext();

    const [errorText, setErrorText] = useState('');
    const [reportPreferences, setReportPreferences] =
        useState<RestaurantReportPreferences>(initialRestaurantPreferences);
    useEffect(() => {
        setFieldValue('reportPreferences', reportPreferences);
    }, [reportPreferences, setFieldValue]);

    const [selectedTime, setSelectedTime] = useState(new Date());

    const handleTimeChange = (newValue: Date) => {
        setSelectedTime(newValue);

        const date = new Date(newValue);
        const time = {
            hour: date.getHours().toString(),
            minute: date.getMinutes().toString(),
        };
        setReportPreferences({
            ...reportPreferences,
            reportTime: {
                ...reportPreferences.reportTime,
                ...time,
            },
        });
    };

    return (
        <Section marginTop={'20px'}>
            <FormControlLabel
                control={<Checkbox checked={showScheduleForm} onChange={handleCheckboxChange} />}
                label={t('Generate over a schedule')}
            />
            <Collapse in={showScheduleForm}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel
                            sx={{
                                color: '#616475',
                                fontWeight: 600,
                            }}
                        >
                            {t('Frequency')}
                        </FormLabel>

                        <ToggleButtonGroup
                            options={[
                                { text: t('Daily'), value: PERIOD.DAILY, tooltip: t('Daily') },
                                { text: t('Weekly'), value: PERIOD.WEEKLY, tooltip: t('Weekly') },
                                { text: t('Monthly'), value: PERIOD.MONTHLY, tooltip: t('Monthly') },
                            ]}
                            size="small"
                            value={reportPreferences.period}
                            onChange={(value) => {
                                setReportPreferences({
                                    ...reportPreferences,
                                    period: value as PERIOD,
                                });
                            }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormLabel
                                sx={{
                                    color: '#616475',
                                    fontWeight: 600,
                                    marginTop: '10px',
                                }}
                            >
                                {t('Receive report at')}
                            </FormLabel>
                            {customTimePickerStyles}
                            <DesktopTimePicker
                                value={selectedTime}
                                onChange={(newValue: Date | null) => handleTimeChange(newValue as Date)}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        fullWidth: true,
                                    },
                                }}
                            />
                        </LocalizationProvider>

                        {reportPreferences.period === PERIOD.WEEKLY && (
                            <FormControl fullWidth>
                                <FormLabel
                                    sx={{
                                        color: '#616475',
                                        fontWeight: 600,
                                    }}
                                >
                                    {t('Receive report every')}
                                </FormLabel>
                                <Select
                                    value={reportPreferences.reportTime?.day || WEEK_DAYS.SUNDAY}
                                    onChange={(event: SelectChangeEvent) => {
                                        setReportPreferences({
                                            ...reportPreferences,
                                            reportTime: {
                                                ...reportPreferences.reportTime,
                                                hour: reportPreferences.reportTime?.hour || '',
                                                minute: reportPreferences.reportTime?.minute || '',
                                                day: event.target.value as WEEK_DAYS | WEEK_DAYS.SUNDAY,
                                            },
                                        });
                                    }}
                                >
                                    <MenuItem value={WEEK_DAYS.SUNDAY}>{t('Sunday')}</MenuItem>
                                    <MenuItem value={WEEK_DAYS.MONDAY}>{t('Monday')}</MenuItem>
                                    <MenuItem value={WEEK_DAYS.TUESDAY}>{t('Tuesday')}</MenuItem>
                                    <MenuItem value={WEEK_DAYS.WEDNESDAY}>{t('Wednesday')}</MenuItem>
                                    <MenuItem value={WEEK_DAYS.THURSDAY}>{t('Thursday')}</MenuItem>
                                    <MenuItem value={WEEK_DAYS.FRIDAY}>{t('Friday')}</MenuItem>
                                    <MenuItem value={WEEK_DAYS.SATURDAY}>{t('Saturday')}</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </FormControl>
                </Grid>

                <Grid item xs={12} sx={{ paddingTop: '10px' }}>
                    <FormControl fullWidth>
                        <FormLabel
                            sx={{
                                color: '#616475',
                                fontWeight: 600,
                            }}
                        >
                            {t('Other Recipients (cc)')}
                        </FormLabel>
                        <Autocomplete
                            sx={{
                                marginBottom: '10px',
                            }}
                            multiple
                            fullWidth
                            id="tags-filled"
                            options={[]}
                            onChange={(event, newVals) => {
                                if (!emailRegex.test(newVals[newVals.length - 1])) {
                                    setErrorText(t('Invalid email ID'));
                                } else {
                                    setErrorText('');
                                }

                                if (!emailRegex.test(newVals[newVals.length - 1])) {
                                    newVals.pop();
                                }

                                setReportPreferences({ ...reportPreferences, cc: newVals });
                            }}
                            onBlur={() => setErrorText('')}
                            freeSolo
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder={t('Email address of recipient')}
                                        helperText={
                                            errorText ||
                                            t('Add multiple email IDs separated by commas for more than one recipient')
                                        }
                                        size="small"
                                        error={!!errorText}
                                    />
                                );
                            }}
                            value={[...reportPreferences.cc]}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormLabel sx={{ fontWeight: '600' }}>{t('Schedule Name')}</FormLabel>
                    <Field name="scheduledfilename">
                        {({ field }: FieldProps) => (
                            <TextField
                                {...field}
                                type="text"
                                fullWidth
                                size="small"
                                sx={{ marginTop: '5px' }}
                                onChange={(e) => {
                                    console.log('e.target.value', e.target.value);

                                    setReportPreferences({
                                        ...reportPreferences,
                                        scheduleName: e.target.value,
                                    });
                                }}
                            />
                        )}
                    </Field>
                </Grid>
            </Collapse>
        </Section>
    );
};

export default GenerateReportOverScheduled;
