import * as Yup from 'yup';
import { saveAs } from 'file-saver';
import { Field, type FieldProps } from 'formik';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import TransactionService from '@/services/transaction';
import OrdersService from '@/services/order';
import { restrictRoles } from '@/constants/roleRestrictions';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import type { DownloadBrandOrderParams } from '@/interfaces/order/types';

import { TimeRangeTypes } from '@/components/TimeRange/Form/types';
import ExportModal from '@/components/ExportModal';
import { commonExportInitialValues } from '@/components/ExportModal/data';
import { FileFormats } from '@/components/ExportModal/types';
import { formatISOTimezone } from '@/components/TimeRange/Form/utils';
import { SelectMultipleRestaurants } from '@/components/SelectMultipleRestaurants';

enum ReportTypes {
    ORDER = 'order',
    TRANSACTION = 'transaction',
}

const OrderExportModal = () => {
    const transactionService = TransactionService.getInstance();
    const orderService = OrdersService.getInstance();
    const { t } = useTranslation('common');
    const { user } = useUserContext();
    const { restaurant } = useRestaurantContext();

    const isBrand = restrictRoles.EXPORT_BRAND_REPORTS.includes(user?.userData?.role);

    const saveFile = (data: Blob, name: string) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, name);
    };

    const getDateQuery = ({ type, from, to }: { type: TimeRangeTypes; from: string | null; to: string | null }) => {
        if (type === 'all' || !from || !to) {
            return {
                startDate: undefined,
                endDate: undefined,
                type: undefined,
            };
        }

        return {
            startDate: formatISOTimezone(from),
            endDate: formatISOTimezone(to),
            type,
        };
    };

    const handleSubmit = async (values: {
        fileFormat: FileFormats;
        type: TimeRangeTypes;
        from: string;
        to: string;
        reportType: ReportTypes;
        restaurantIds?: string[];
    }) => {
        const { restaurantIds, from, to, type, reportType, fileFormat } = values;
        const { endDate, startDate, ...dateQuery } = getDateQuery({ from, to, type });

        let id = restaurant?.id;
        let request = transactionService.downloadRestaurantTransaction;

        const body: Omit<DownloadBrandOrderParams, 'restaurantIds'> & { restaurantIds?: string } = { fileFormat };
        body.endDate = endDate;
        body.startDate = startDate;
        body.type = dateQuery.type;

        if (isBrand) {
            id = restaurant?.brand_id;
            body.restaurantIds = restaurantIds?.join(',');
            if (reportType === ReportTypes.ORDER) {
                request = orderService.downloadBrandOrder;
            } else {
                request = transactionService.downloadBrandTransaction;
            }
        } else if (reportType === ReportTypes.ORDER) {
            request = orderService.downloadRestaurantOrder;
        }

        await request(id || '', body)
            .then((response) => {
                saveFile(response.data, `${reportType}s.${fileFormat}`);
            })
            .catch((err) => console.log(err));
    };

    return (
        <ExportModal
            extraValidations={{
                reportType: Yup.string()
                    .oneOf(Object.values(ReportTypes), t('Please select one of the given values'))
                    .required(t('Report type is required')),
                restaurantIds: isBrand ? Yup.array().of(Yup.string().required()).nullable() : Yup.string().nullable(),
            }}
            initialValues={{
                ...commonExportInitialValues,
                reportType: ReportTypes.ORDER,
            }}
            onConfirm={handleSubmit}
            reportTypes={[
                { label: t('Order View'), value: ReportTypes.ORDER },
                { label: t('Transaction View'), value: ReportTypes.TRANSACTION },
            ]}
            timeSelectEnabled
            title={t('Export Payments')}
        >
            {isBrand && (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>{t('Restaurants')}</FormLabel>
                        <Field name="restaurantIds">
                            {({ field: { name }, meta, form: { setFieldValue } }: FieldProps) => (
                                <SelectMultipleRestaurants
                                    onChange={(value) => {
                                        setFieldValue(name, value);
                                    }}
                                    restaurantListToExport={meta.value}
                                />
                            )}
                        </Field>
                    </FormControl>
                </Grid>
            )}
        </ExportModal>
    );
};

export default OrderExportModal;
